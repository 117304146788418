const MenuItems = [
  'About',
  'Education',
  'Experience',
  'Projects',
  'Music',
  'Contact',
];

export default MenuItems;
